import React from "react";
import { Link, useLocation, Navigate } from "react-router-dom";

export const Thanks = () => {
  const location = useLocation();

  if (!location.state?.formSubmitted) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <main>
        <div className="banner home-banner">
          <div className="container">
            <div className="content">
              <h1>Thank You!</h1>
              <p>Your query is submitted. I'll be in touch shortly.</p>
              <div className="btn">
                <Link to="/">Back to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
